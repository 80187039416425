<template >
  <v-container>
    <v-dialog
        v-model="showModal"
        :value="true"
        max-width="400"
        persistent
        @click:outside="showModal=false"
    >
      <v-card class="px-6">
        <div class="d-flex justify-between pt-4">
          <v-card-text>
            <h3 class="primary-modal-title black--text">選擇商店</h3>
          </v-card-text>
          <v-btn
              @click="showModal=false"
              text
              icon
          >
            <v-icon>ri-close-line</v-icon>
          </v-btn>
        </div>
        <div class="pb-6">
          <v-select dense outlined hide-details :items="storeList" label="商店" v-model="selectedStore" />
        </div>
        <div class="d-flex justify-center pb-6">
          <v-btn
            @click.prevent="()=>createOrder()"
            color="secondary"
            large
            >
            成立訂單
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
    <v-row>
      <v-col :cols="8">
        <div class="d-flex align-center">
          <page-title title="建立訂單" />
        </div>
      </v-col>
      <v-col cols="4" class="d-flex justify-end align-center">
        <v-btn
          @click.prevent="nextStep"
          color="secondary"
          large
        >
          下一步
        </v-btn>
      </v-col>
    </v-row>
    <div class="mt-4">
      <v-card
          class="mb-4"
          v-for="row in itemsList || []"
          :key="row.id"
        >
          <div class="d-flex flex-no-wrap card-inner">
            <div class="d-inline-flex">
              <div class="h-100 mt-0 pt-0 pl-2">
                <v-checkbox v-model="row.selected" class="mt-0 mx-0"/>
              </div>
              <v-img
                v-if="row.photo"
                :width="150"
                :height="150"
                cover
                :src="row.photo"
              />
              <v-img
                v-else
                :width="150"
                :height="150"
                cover
                src="~@/assets/product-dafault.jpeg"
              />
            </div>
            <div style="min-height: 150px;">
              <v-card-subtitle class="text-h6">
                {{ row.name }}
              </v-card-subtitle>
              <div class="pl-4">
                <number-input
                  v-model="row.quantity"
                  :max="999"
                  name="quantity"
                />
              </div>
            </div>
          </div>
        </v-card>
    </div>
  </v-container>
</template>

<script lang="babel" type="text/babel">

import ItemMixin from '@/components/form/custom/ItemMixin.js'
import orderConstants from "@/modules/base/config/orderConstants"

export default {
    mixins: [ItemMixin],
    components: {
        "number-input": () => import("@/modules/base/components/numberInput.vue"),
    },
    props : {
        orderStatus : String,
        defaultKey : String
    },
    data: () => ({
        showModal: false,
        orderConstants : orderConstants,
        estimated_delivery_days: 1,
        itemsList: [],
        storeList: [],
        selectedStore: "",
    }),
    async created() { 
    
    },
    async mounted(){
      await this.getStoreList();
      await this.getProductList();
    },
    watch : {
        
    },
    computed: {
      tokenRole() {
        return this.$store.getters['token/tokenRole']
      },
      providerId() {
        if(this.tokenRole == 'provider') return this.$store.getters['member/providerId']
        return ''
      },
    },
    methods: {
        async getStoreList(params) {
          const res = await this.$api.collection.providerApi.clientIndex(this.providerId, params)
          this.storeList = res.data.map((store)=>{
            return {
              text:store.name,
              value:store.store_id
            }
          });
        },
        async getProductList(params) {
          const res = await this.$api.collection.providerApi.getProducts(this.providerId)
          this.itemsList = res.data.map((product)=>{
            return {
              ...product,
              quantity: 0,
              selected: false,
              photo: this.$helper.getPhotoUrl(product.photos[0])
            }
          });
        },
        nextStep(){
          const selectedProducts = this.itemsList.filter((item) => item.selected === true && item.quantity > 0);
          if(selectedProducts.length > 0){
            this.showModal = true;
            return;
          }
          return this.$snotify.warning("請選擇商品並填寫數量");
        },
        async createOrder(){
          if(!this.selectedStore) return this.$snotify.success("請選擇商店");
          this.showModal = false;
          try{
            const param = {
              carts: this.itemsList.filter((item) => item.selected === true && item.quantity > 0).map((item)=>{
                return {
                  productId: item.id,
                  quantity: item.quantity
                }
              }),
              createPageDatetime: this.$route.query.createPageDatetime
            }
            const res = await this.$api.collection.providerApi.createManual(this.selectedStore,this.providerId,param);
            this.$snotify.success("成功建立訂單");
            this.$router.push({
              name: "order-detail",
              params: {orderId: res.order_no, providerId: this.$store.getters[`member/providerId`]},
            });
          }catch(e){
            this.$snotify.error(
              this.$t('error.unexpect'),
              this.$t('delete.failure')
            );
            console.log(e)
          }
        }
    },
}
</script>
<style scoped>
.card-inner{
  padding-right: 10px;
  align-items: center;
}
</style>

